import React from 'react';

const IconLoader = () => (
  <svg id="logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
    {/* <title>Loader Logo</title> */}
    <g>
      <g id="B" transform="translate(11.000000, 5.000000)">
        {/* <path
            d="M45.691667,45.15 C48.591667,46.1 50.691667,48.95 50.691667,52.2 C50.691667,57.95 46.691667,61 40.291667,61 L28.541667,61 L28.541667,30.3 L39.291667,30.3 C45.691667,30.3 49.691667,33.15 49.691667,38.65 C49.691667,41.95 47.941667,44.35 45.691667,45.15 Z M33.591667,43.2 L39.241667,43.2 C42.791667,43.2 44.691667,41.85 44.691667,38.95 C44.691667,36.05 42.791667,34.8 39.241667,34.8 L33.591667,34.8 L33.591667,43.2 Z M33.591667,47.5 L33.591667,56.5 L40.191667,56.5 C43.691667,56.5 45.591667,54.75 45.591667,52 C45.591667,49.2 43.691667,47.5 40.191667,47.5 L33.591667,47.5 Z"
            fill="currentColor"
          /> */}
        <svg
          width="43"
          height="60"
          viewBox="0 0 43 60"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path d="M0 0H43V60H0V0Z" fill="black" />
          <path
            d="M14.7273 56V21.0909H26.5227C29.2614 21.0909 31.5 21.5852 33.2386 22.5739C34.9886 23.5511 36.2841 24.875 37.125 26.5455C37.9659 28.2159 38.3864 30.0795 38.3864 32.1364C38.3864 34.1932 37.9659 36.0625 37.125 37.7443C36.2955 39.4261 35.0114 40.767 33.2727 41.767C31.5341 42.7557 29.3068 43.25 26.5909 43.25H18.1364V39.5H26.4545C28.3295 39.5 29.8352 39.1761 30.9716 38.5284C32.108 37.8807 32.9318 37.0057 33.4432 35.9034C33.9659 34.7898 34.2273 33.5341 34.2273 32.1364C34.2273 30.7386 33.9659 29.4886 33.4432 28.3864C32.9318 27.2841 32.1023 26.4205 30.9545 25.7955C29.8068 25.1591 28.2841 24.8409 26.3864 24.8409H18.9545V56H14.7273Z"
            fill="#64FFDA"
          />
        </svg>
      </g>
      {/* <path
        stroke="currentColor"
        strokeWidth="5"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M 50, 5
                  L 11, 27
                  L 11, 72
                  L 50, 95
                  L 89, 73
                  L 89, 28 z"
      /> */}
      <path
        d="M71.282 60.8453C71.282 61.5598 70.9008 62.2201 70.282 62.5774L37.641 81.4226C37.0222 81.7799 36.2598 81.7799 35.641 81.4226L3 62.5773C2.3812 62.2201 2 61.5598 2 60.8453V23.1547C2 22.4402 2.3812 21.7799 3 21.4226L35.641 2.57735C36.2598 2.22009 37.0222 2.22008 37.641 2.57735L70.282 21.4226C70.9008 21.7799 71.282 22.4402 71.282 23.1547V60.8453Z"
        stroke="#5EF4DA"
        strokeWidth="4"
      />
    </g>
  </svg>
);

export default IconLoader;
